import {ApolloLink} from '@volkswagen-onehub/onegraph-client';

/**
 * Adds the client identifying headers `apollographql-client-name` and
 * `apollographql-client-version` to the GraphQL request.
 *
 * The client name and client version values are based on the property
 * `consumerId` of the Operation Context.
 *
 * E.g. the consumerId value "hello-world@1.2.3" adds the headers
 *
 * ```
 * apollographql-client-name: hello-world
 * apollographql-client-version: 1.2.3
 * ```
 */
export function createConsumerIdHeaderLink(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    const {consumerId} = operation.getContext() as { consumerId: string };
    if (consumerId) {
      const match = consumerId.match(/(.*)@(.*)/);
      let parsedClientId: string;
      let parsedVersion = 'unknown';

      if (match) {
        [, parsedClientId, parsedVersion] = match;
      } else {
        parsedClientId = consumerId;
      }

      operation.setContext(({headers = {}}) => ({
        headers: {
          ...headers,
          'apollographql-client-name': parsedClientId,
          'apollographql-client-version': parsedVersion,
        },
      }));
    }
    return forward(operation);
  });
}

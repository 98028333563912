import {useCallback} from 'react';

import {useConfiguration} from '../base/use-configuration';

export const useConfigurationField = (field: string): string => {
  const resolveValue = useCallback(<T>(fieldRef, receivedData): T => {
    const fieldRefs = fieldRef.split('.');
    return fieldRefs.reduce(
      (value, ref) => (value ? value[ref] : undefined),
      receivedData,
    );
  }, []);

  const {data} = useConfiguration();

  return resolveValue<string>(field, data);
};

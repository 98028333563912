import React from 'react';

import {ColaState} from './state';
import {ColaAction} from './actions';

export type ColaContextValue = {
  state: ColaState;
  dispatch: React.Dispatch<ColaAction>;
};

const contextSymbol = typeof Symbol === 'function' && Symbol.for
  ? Symbol.for('__COLA_CONTEXT__')
  : '__COLA_CONTEXT__';

const resetColaContext = (): void => {
  Object.defineProperty(React, contextSymbol, {
    value: React.createContext<ColaContextValue>(undefined),
    enumerable: false,
    configurable: true,
    writable: false,
  });
};

export const getColaContext = (): React.Context<ColaContextValue> => {
  if (!(React as unknown)[contextSymbol]) {
    resetColaContext();
  }

  return (React as unknown)[contextSymbol] as React.Context<ColaContextValue>;
};

import React from 'react';

import jwtDecode from 'jwt-decode';
import {FeatureServiceBinding} from '@feature-hub/core';
import {FeatureServiceImplementation} from '@volkswagen-onehub/sonic-feature-hub-tools';
import {
  ApolloClient,
  ChangeType, ConfigurationIdentifier,
  gql,
  IdentifierType,
  ItemGroup,
  NormalizedCacheObject,
} from '@volkswagen-onehub/onegraph-client';
import {defineExternals} from '@feature-hub/module-loader-amd';

import {name as packageName} from '../../package.json';
import {
  AudiColaServiceDependencies,
  AudiColaServiceOptions,
  defineAudiColaService,
  useConfiguration,
  useConfigurationChange,
  useConfigurationField,
  useConfigurationInitWithItems,
  useConfigurationItems,
} from '../audi-cola-service';
import {createColaProvider, ColaProviderProps, CURRENT_CONFIGURATION_CONTEXT} from './context/provider';
import {createApolloClient, deleteApolloClient, getSerializedStore} from './client/client';
import {ColaContextValue, getColaContext} from './context/context';
import {renderFeatureApp, resetPrefetchedApps} from './render-feature-app/renderer-feature-app';

type Callback = (identifier: ConfigurationIdentifier) => void;

type Payload = Map<string, Callback[]>;

type RegisterFunction = (context: string, initialConfiguration: ConfigurationIdentifier, callback: Callback) => void;

export interface AudiColaServiceV1 {
  ColaProvider: React.FC<ColaProviderProps>;
  setInitialConfiguration: (context: string, initialConfiguration?: ConfigurationIdentifier) => void;
  getApolloClient: () => ApolloClient<NormalizedCacheObject>;
  getColaContext: () => React.Context<ColaContextValue>;
  registerForContext: RegisterFunction;
  getIdentifier: (configurationContext: string) => ConfigurationIdentifier;
  updateIdentifier: (context: string, identifier: ConfigurationIdentifier) => void;
  getPreviewContext: () => string | undefined;
  getSerializedStore: () => string,
  clearStore: () => void,
  removeQueryFromCache: (queryName: string, queryArguments: Record<string, unknown>) => void,
  prepareRender: (App: () => JSX.Element) => JSX.Element;
  resetConfiguration: () => void;
}

export const AudiColaServiceV1Implementation: FeatureServiceImplementation<
  AudiColaServiceV1, AudiColaServiceOptions, AudiColaServiceDependencies
> = (options, {featureServices}) => {
  // TODO initialize identifiers from sessionStorage if available
  let defaultContextSynced = false;
  const identifiers: Record<string, ConfigurationIdentifier> = {};
  const registered = new Map<string, Payload>();

  const getPayloadForContext = (context: string): Payload => {
    return registered.has(context)
      ? registered.get(context)
      : new Map<string, Callback[]>();
  };

  const registerConsumerCallback = (context: string, consumerId: string, callback: Callback): void => {
    const payload = getPayloadForContext(context);
    if (!payload.has(consumerId)) {
      payload.set(consumerId, []);
    }
    payload.get(consumerId).push(callback);

    registered.set(context, payload);
  };

  const removeQueryFromCache = (queryName: string, queryArguments: Record<string, unknown>): void => {
    const {cache} = getApolloClient();
    cache.evict({
      fieldName: queryName,
      args: {
        ...queryArguments,
      },
    });
    cache.gc();
  };

  const setInitialConfiguration = (context: string, initialConfiguration?: ConfigurationIdentifier): void => {
    const isCurrentConfigurationContext = context === CURRENT_CONFIGURATION_CONTEXT;
    let initialIdentifier = initialConfiguration;
    const currentCarlineService = featureServices['audi-current-carline-service'];

    if (isCurrentConfigurationContext && currentCarlineService) {
      initialIdentifier = currentCarlineService.prstring
        ? {
          type: IdentifierType.Prstring,
          identifier: currentCarlineService.prstring,
        }
        : {
          type: IdentifierType.Carline,
          identifier: currentCarlineService.carline,
        };
    }

    if (!identifiers[context]) {
      identifiers[context] = initialIdentifier;
    }

    // update from nemo
    if (typeof document !== 'undefined') {
      document.addEventListener('configurationUpdate', updateDataFromEvent);
    }
  };

  const resetConfiguration = (): void => {
    const event = new CustomEvent('resetConfiguration');
    document.dispatchEvent(event);
  };

  const updateDataFromEvent = (evt): void => {
    if (evt['detail']?.configuration?.prstring) {
      const prString: string = evt['detail']?.configuration?.prstring;
      updateIdentifier(CURRENT_CONFIGURATION_CONTEXT, {type: IdentifierType.Prstring, identifier: prString});
    }
  };

  const initialSyncWithNemo = (context: string): void => {
    if (typeof document !== 'undefined' && context === CURRENT_CONFIGURATION_CONTEXT && !defaultContextSynced) {
      const configurationDataRequest = new CustomEvent(
        'configurationDataRequest',
      );
      document.dispatchEvent(configurationDataRequest);
      defaultContextSynced = true;
    }
  };

  const getIdentifier = (configurationContext: string): ConfigurationIdentifier => {
    return identifiers[configurationContext];
  };

  const updateIdentifier = (context: string, identifier: ConfigurationIdentifier): void => {
    // TODO persist identifiers in sessionStorage
    if (identifiers[context] !== identifier) {
      identifiers[context] = identifier;
      notifyContext(context);
    }
  };

  const notifyContext = (context: string): void => {
    getPayloadForContext(context).forEach((subscribers) => {
      subscribers.forEach((subscriber) => subscriber(identifiers[context]));
    });
  };

  const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
    const jwtToken = options?.preview?.jwtToken;
    const colaUrl = featureServices['audi-market-context-service']?.getContextItem<string>('COLA_URL') || options.colaUrl;
    const colaDataVersionsUrl = options?.colaDataVersionsUrl;

    if (!colaUrl) {
      throw new Error('No CoLa-Url provided. Either pass it from integrator or through market context service.');
    }

    return createApolloClient({
      featureServices,
      colaUrl,
      jwtToken,
      colaDataVersionsUrl,
    });
  };

  const getPreviewContext = (): string | undefined => {
    const jwtToken = options?.preview?.jwtToken;

    if (!jwtToken) {
      return undefined;
    }

    try {
      const decodedJwt = jwtDecode(jwtToken);
      return decodedJwt ? decodedJwt['mandant-id'] : undefined;
    } catch (e) {
      featureServices['s2:logger']?.error('Parsing preview JWTToken failed', e);
    }

    return undefined;
  };

  const clearStore = (): void => {
    const colaUrl = featureServices['audi-market-context-service']?.getContextItem<string>('COLA_URL') || options.colaUrl;

    deleteApolloClient(colaUrl);
    resetPrefetchedApps();
  };

  const exposeExternals = (): void => {
    const exportedMethods = {
      ChangeType,
      defineAudiColaService,
      IdentifierType,
      ItemGroup,
      useConfiguration,
      useConfigurationChange,
      useConfigurationField,
      useConfigurationInitWithItems,
      useConfigurationItems,
      gql,
    };

    if (!featureServices['s2:async-ssr-manager']) {
      defineExternals({
        [packageName]: exportedMethods,
      });
    }
  };

  exposeExternals();

  return {
    create: (consumerId): FeatureServiceBinding<AudiColaServiceV1> => {
      const registerForContext: RegisterFunction = (context, initialConfiguration, callback) => {
        registerConsumerCallback(context, consumerId, callback);
        setInitialConfiguration(context, initialConfiguration);
        callback(identifiers[context]);
        initialSyncWithNemo(context);
      };

      return {
        featureService: {
          ColaProvider: createColaProvider(consumerId),
          setInitialConfiguration,
          getApolloClient,
          getColaContext,
          registerForContext,
          getIdentifier,
          updateIdentifier,
          getPreviewContext,
          getSerializedStore,
          clearStore,
          removeQueryFromCache,
          prepareRender: (App): JSX.Element => renderFeatureApp(consumerId, getApolloClient(), featureServices, App),
          resetConfiguration,
        },
        unbind: (): void => (
          registered.forEach((context) => context.delete(consumerId))
        ),
      };
    },
  };
};

import {useEffect, useRef} from 'react';

export const usePreviousRefValue = <T>(value: T): T => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@volkswagen-onehub/onegraph-client"), require("@feature-hub/module-loader-amd"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@volkswagen-onehub/onegraph-client", "@feature-hub/module-loader-amd", "axios"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("@volkswagen-onehub/onegraph-client"), require("@feature-hub/module-loader-amd"), require("axios")) : factory(root["react"], root["@volkswagen-onehub/onegraph-client"], root["@feature-hub/module-loader-amd"], root["axios"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__21__) {
return 
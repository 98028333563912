import {useContext, useMemo} from 'react';

import {
  Configuration,
  gql,
  Query,
  QueryConfigurationArgs,
  QueryResult,
  useQuery,
} from '@volkswagen-onehub/onegraph-client';

import {getColaContext} from '../../context/context';
import {useRequiredVariables} from '../internal/use-require-variables';
import {usePriceMode} from '../internal/use-price-mode';
import {useQueryData} from '../internal/use-query-data';

export interface ConfigurationResult {
  query: QueryResult<Query, QueryConfigurationArgs>,
  data: Configuration;
}

export const useConfiguration = (customQuery?: string): ConfigurationResult => {
  const {state} = useContext(getColaContext());

  const query = useMemo(() => (
    customQuery
      ? gql`${customQuery}`
      : gql`${state.queryService.QUERY_CONFIGURATION_BASE_DATA}`
  ),
  [customQuery, state.queryService.QUERY_CONFIGURATION_BASE_DATA]);

  const queryVariables = useRequiredVariables();
  const priceMode = usePriceMode('configuration', queryVariables);

  const queryState = useQuery<Query, QueryConfigurationArgs>(query, {
    variables: {
      ...queryVariables,
      priceMode,
    },
    context: {
      consumerId: `${state.clientName}@${state.clientVersion}`,
    },
  });
  const queryData = useQueryData<Configuration, QueryConfigurationArgs>(queryState, 'configuration');

  return {
    query: queryState,
    data: queryData,
  };
};

import {useMemo, useRef} from 'react';

import {Query, QueryResult} from '@volkswagen-onehub/onegraph-client';

export const useQueryData = <Result, Args>(queryState: QueryResult<Query, Args>, queryName: string): Result => {
  const lastQueryData = useRef<Result>(undefined);

  return useMemo(() => {
    const {loading, called, error, data} = queryState;
    if (error) {
      console.error('[audi-cola-service]', error);
    }
    if (!loading && called && !error) {
      lastQueryData.current = data?.[queryName] as Result;
    }

    return lastQueryData.current;
  }, [queryName, queryState]);
};

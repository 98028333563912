import React from 'react';

import {ApolloClient, getDataFromTree, NormalizedCacheObject} from '@volkswagen-onehub/onegraph-client';

import {AudiColaServiceDependencies} from '../../audi-cola-service';

const prefetchedFeatureApps = new Map<string, boolean>();

export const resetPrefetchedApps = (): void => {
  prefetchedFeatureApps.clear();
};

export const renderFeatureApp = (
  consumerId: string,
  client: ApolloClient<NormalizedCacheObject>,
  featureServices: AudiColaServiceDependencies,
  App: () => JSX.Element): JSX.Element => {
  const asyncSsrManager = featureServices['s2:async-ssr-manager'];
  const prefetched = prefetchedFeatureApps.get(consumerId);

  if (asyncSsrManager && !prefetched) {
    const prefetchPromise = new Promise<void>((resolve) => {
      getDataFromTree(<App />, {client}).then(() => {
        prefetchedFeatureApps.set(consumerId, true);
        resolve();
      });
    });

    asyncSsrManager.scheduleRerender(prefetchPromise);
  }

  return <App/>;
};

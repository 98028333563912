import {useContext, useMemo} from 'react';

import {PriceMode} from '@volkswagen-onehub/onegraph-client';

import {getColaContext} from '../../context/context';
import {usePreviousRefValue} from './use-previous-ref-value';

export const usePriceMode = (queryName: string, queryVariables: Record<string, unknown>): PriceMode => {
  const {state: {colaService, priceMode}} = useContext(getColaContext());
  const previousPriceMode = usePreviousRefValue<PriceMode>(priceMode);

  return useMemo(
    () => {
      if (previousPriceMode && priceMode !== previousPriceMode) {
        colaService.removeQueryFromCache(
          queryName,
          {
            ...queryVariables,
            priceMode,
          });
      }

      return priceMode;
    },
    [colaService, previousPriceMode, priceMode, queryName, queryVariables]
  );
};

import {ConfigurationIdentifier, PriceMode} from '@volkswagen-onehub/onegraph-client';

export enum ActionTypes {
  SetConfigurationIdentifierAction,
  SetPriceModeAction,
}

interface SetConfigurationIdentifierActionPayload {
  identifier: ConfigurationIdentifier;
}

interface SetConfigurationIdentifierAction {
  type: ActionTypes.SetConfigurationIdentifierAction;
  payload: SetConfigurationIdentifierActionPayload;
}

interface SetPriceModeAction {
  type: ActionTypes.SetPriceModeAction;
  payload: PriceMode;
}

export type ColaAction =
  | SetConfigurationIdentifierAction
  | SetPriceModeAction;

export const createSetConfigurationIdentifierAction:
  (identifier: ConfigurationIdentifier) => SetConfigurationIdentifierAction =
  (identifier) => {
    return {
      payload: {
        identifier,
      },
      type: ActionTypes.SetConfigurationIdentifierAction,
    };
  };

export const createSetPriceModeAction:
  (priceMode: PriceMode) => SetPriceModeAction =
  (priceMode) => {
    return {
      payload: priceMode,
      type: ActionTypes.SetPriceModeAction,
    };
  };

import {useContext, useMemo} from 'react';

import {MutationConfigurationChangeArgs, QueryConfigurationArgs, QueryItemsByGroupArgs} from '@volkswagen-onehub/onegraph-client';

import {useMarketContext} from './use-market-context';
import {getColaContext} from '../../context/context';

type RequiredVariables =
  | QueryConfigurationArgs
  | Omit<MutationConfigurationChangeArgs, 'change'>
  | Omit<QueryItemsByGroupArgs, 'itemGroup'>;

export const useRequiredVariables = (): RequiredVariables => {
  const {state} = useContext(getColaContext());
  const {identifier: configIdentifier} = state;
  const marketContext = useMarketContext(state);

  return useMemo(() => (
    configIdentifier
      ? {
        configIdentifier,
        marketContext,
      }
      : undefined
  ),
  [configIdentifier, marketContext]);
};

import {createOneGraphClient, deleteOneGraphClient, ApolloClient, NormalizedCacheObject} from '@volkswagen-onehub/onegraph-client';

import {name as clientName, version as clientVersion} from '../../../package.json';
import {AudiColaServiceDependencies} from '../../audi-cola-service';
import {createConsumerIdHeaderLink} from './consumer-id-header-link';
import {createVersionsLink} from './versions-link';

let client: ApolloClient<NormalizedCacheObject> = null;

export function deleteApolloClient(colaUrl: string): void {
  deleteOneGraphClient({
    endpoint: colaUrl,
    clientName,
    clientVersion,
  });
  client = null;
}

export const getSerializedStore = (): string => {
  return client ? JSON.stringify(client.extract()) : '';
};

const getUnserializedStore = (serializedStore: string): NormalizedCacheObject => {
  return JSON.parse(serializedStore);
};

const getStoreFromDOM = (isSsr: boolean): NormalizedCacheObject => {
  let unserializedStore = {};

  if (!isSsr && typeof document !== 'undefined') {
    const serializedStore = document.querySelector('script[type="x-feature-hub/serialized-cola-store"]')?.textContent;

    if (serializedStore) {
      unserializedStore = getUnserializedStore(serializedStore);
    }
  }

  return unserializedStore;
};

const merge = (existing = {}, incoming): unknown => {
  if (incoming === null) {
    return null;
  }

  return {...existing, ...incoming};
};

interface CreateApolloClientParams {
  featureServices: AudiColaServiceDependencies;
  colaUrl: string;
  colaDataVersionsUrl?: string;
  jwtToken?: string;
}

export const createApolloClient = ({
  featureServices,
  colaUrl,
  colaDataVersionsUrl,
  jwtToken,
}: CreateApolloClientParams): ApolloClient<NormalizedCacheObject> => {
  if (!client) {
    const ssrMode = Boolean(featureServices['s2:async-ssr-manager']);
    const ssrState = getStoreFromDOM(ssrMode);

    client = createOneGraphClient({
      endpoint: colaUrl,
      clientName,
      clientVersion,
      ssrMode,
      customHeaders: jwtToken ? {authorization: `Bearer ${jwtToken}`} : {},
      customLinks: [createVersionsLink(colaDataVersionsUrl), createConsumerIdHeaderLink()],
      disableAPQ: !colaDataVersionsUrl,
      cacheConfig: {
        resultCaching: true,
        typePolicies: {
          Configuration: {
            keyFields: ['prString'],
            fields: {
              prices: {merge},
              renderImages: {merge},
              emissionAndConsumption: {merge},
              techData: {merge},
            },
          },
          Option: {
            keyFields: false,
          },
          DataEntry: {
            keyFields: false,
          },
          Prices: {
            fields: {
              total: {merge},
              base: {merge},
              options: {merge},
              rotr: {merge},
              financing: {merge},
            },
          },
          Financing: {
            fields: {
              rate: {merge},
            },
          },
        },
      },
    });

    client.cache.restore(ssrState);
  }

  return client;
};

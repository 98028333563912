import * as React from 'react';

import {ColaState} from './state';
import {ActionTypes, ColaAction} from './actions';

export const colaReducer: React.Reducer<ColaState, ColaAction> = (state, action) => {
  switch (action.type) {
    case ActionTypes.SetConfigurationIdentifierAction: {
      // TODO save identifiers in sessionStorage
      return {
        ...state,
        identifier: action.payload.identifier,
      };
    }
    case ActionTypes.SetPriceModeAction: {
      return {
        ...state,
        priceMode: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

import {useContext, useMemo} from 'react';

import {
  gql,
  ItemGroup,
  Option,
  Query,
  QueryItemsByGroupArgs,
  QueryResult,
  useQuery,
} from '@volkswagen-onehub/onegraph-client';

import {getColaContext} from '../../context/context';
import {useRequiredVariables} from '../internal/use-require-variables';
import {usePriceMode} from '../internal/use-price-mode';
import {useQueryData} from '../internal/use-query-data';

export interface ItemsByGroupResult {
  query: QueryResult<Query, QueryItemsByGroupArgs>,
  data: Option[];
}

export const useConfigurationItems = (itemGroup: ItemGroup): ItemsByGroupResult => {
  const {state} = useContext(getColaContext());
  const query = useMemo(() => (gql`${state.queryService.QUERY_ITEMS_BY_GROUP}`), [state.queryService.QUERY_ITEMS_BY_GROUP]);

  const queryVariables = useRequiredVariables();
  const priceMode = usePriceMode('itemsByGroup', {
    ...queryVariables,
    itemGroup,
  });

  const queryState = useQuery<Query, QueryItemsByGroupArgs>(query, {
    variables: {
      ...queryVariables,
      itemGroup,
      priceMode,
    },
    context: {
      consumerId: `${state.clientName}@${state.clientVersion}`,
    },
  });
  const queryData = useQueryData<Option[], QueryItemsByGroupArgs>(queryState, 'itemsByGroup');

  return {
    query: queryState,
    data: queryData,
  };
};

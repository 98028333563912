import axios from 'axios';
import sha256 from 'tiny-sha256';
import {ApolloLink, setContext} from '@volkswagen-onehub/onegraph-client';

let versionsLoader: Promise<string> = undefined;

export const getVersions = (colaDataVersionsUrl: string): Promise<string> => {
  if (!colaDataVersionsUrl) {
    return Promise.resolve('');
  }

  if (!versionsLoader) {
    versionsLoader = new Promise<string>((resolve) => {
      axios.get(colaDataVersionsUrl).then((value) => {
        if (value.status === 200) {
          const data = JSON.stringify(value.data);
          resolve(data);
          return;
        }
        throw new Error();
      }).catch(() => {
        console.warn('[audi-cola-service] could not get mbv versions, this can result in outdated cola data.');
        resolve('');
      });
    });
  }

  return versionsLoader;
};

export const createVersionsLink = (colaDataVersionsUrl: string): ApolloLink => {
  return setContext(async (operation, prevContext) => {
    const colaDataVersions = await getVersions(colaDataVersionsUrl);
    operation.extensions['colaDataVersions'] = await sha256(colaDataVersions);

    return {...prevContext, colaDataVersions};
  });
};

import {useMemo} from 'react';

import {MarketContext} from '@volkswagen-onehub/onegraph-client';

import {ColaState} from '../../context/state';

export const useMarketContext = (state: ColaState): MarketContext => {
  const {localeService, previewContext} = state;

  return useMemo(() => ({
    country: localeService.countryCode.toLowerCase(),
    language: localeService.language.toLowerCase(),
    ...previewContext ? {previewContext} : {},
  }),
  [localeService.countryCode, localeService.language, previewContext]);
};

import {useCallback, useContext, useEffect, useMemo} from 'react';

import {
  Configuration,
  gql,
  IdentifierType,
  LazyQueryResult,
  Query,
  QueryConfigurationInitWithItemsArgs,
  useLazyQuery,
} from '@volkswagen-onehub/onegraph-client';

import {getColaContext} from '../../context/context';
import {useMarketContext} from '../internal/use-market-context';

export interface ConfigurationInitWithItemsResult {
  query: LazyQueryResult<Query, QueryConfigurationInitWithItemsArgs>,
  data: Configuration;
}

export const useConfigurationInitWithItems = (carline: string, items: string[]): ConfigurationInitWithItemsResult => {
  const {state} = useContext(getColaContext());

  const queryString = `query ConfigurationInitWithItems(
  $carline: String!
  $items: [String!]!
  $marketContext: MarketContext!
) {
  configurationInitWithItems(carline: $carline items: $items marketContext: $marketContext) {
    prString
  }
}`;

  const query = useMemo(() => (gql`${queryString}`), [queryString]);
  const marketContext = useMarketContext(state);
  const queryVariables: QueryConfigurationInitWithItemsArgs = useMemo(() => ({
    carline,
    items,
    marketContext,
  }), [carline, items, marketContext]);

  const [executeQuery, queryState] =
    useLazyQuery<Query, QueryConfigurationInitWithItemsArgs>(query, {
      variables: queryVariables,
      context: {
        consumerId: `${state.clientName}@${state.clientVersion}`,
      },
      onCompleted: (data) => {
        if (data.configurationInitWithItems?.prString) {
          state.colaService.updateIdentifier(state.configurationContext, {
            type: IdentifierType.Prstring,
            identifier: data.configurationInitWithItems.prString,
          });
        }
      },
    });
  const {data, called} = queryState;

  const hasRequiredVariables = useCallback((): boolean => {
    return Boolean(carline) && items && items.length > 0;
  }, [carline, items]);

  useEffect(() => {
    if (!called && hasRequiredVariables()) {
      executeQuery();
    }
  }, [called, executeQuery, hasRequiredVariables]);

  return {
    query: queryState,
    data: data?.configurationInitWithItems,
  };
};

import React, {useMemo} from 'react';

import {
  ApolloClient,
  MockedProvider,
  MockedProviderProps,
  NormalizedCacheObject,
  ConfigurationIdentifier,
  IdentifierType,
  PriceMode,
} from '@volkswagen-onehub/onegraph-client';

import {AudiColaServiceV1} from '../audi-cola-service-v1';
import {getColaContext} from './context';
import {ColaState} from './state';
import {colaReducer} from './reducers';
import {ColaProviderRequiredServices, CURRENT_CONFIGURATION_CONTEXT} from './provider';
import {ColaAction} from './actions';

export const MockColaProvider: React.FC<MockedProviderProps> = (
  {children, ...props}
) => {
  const featureServices = getMockColaFeatureServices();
  const ColaContext = getColaContext();
  const colaService = useMemo((): AudiColaServiceV1 => featureServices['audi-cola-service'], [featureServices]);

  const [state, dispatch] = React.useReducer<React.Reducer<ColaState, ColaAction>>(colaReducer, {
    identifier: colaService.getIdentifier(CURRENT_CONFIGURATION_CONTEXT),
    configurationContext: CURRENT_CONFIGURATION_CONTEXT,
    priceMode: PriceMode.Price,
    colaService: featureServices['audi-cola-service'],
    localeService: featureServices['locale-service'],
    queryService: featureServices['audi-cola-query-service'],
    previewContext: featureServices['audi-cola-service'].getPreviewContext(),
    asyncSsrManager: featureServices['s2:async-ssr-manager'],
    clientName: 'jest',
    clientVersion: '0.0.0',
  });

  return <ColaContext.Consumer>
    {(context): React.ReactElement => {
      const contextProps = context ? context : {state, dispatch};

      return (
        <ColaContext.Provider value={contextProps}>
          <MockedProvider {...props}>
            {children}
          </MockedProvider>
        </ColaContext.Provider>
      );
    }}
  </ColaContext.Consumer>;
};

const getMockColaFeatureServices = (): ColaProviderRequiredServices => ({
  'audi-cola-service': {
    ColaProvider: undefined,
    setInitialConfiguration: jest.fn(),
    getApolloClient: (): ApolloClient<NormalizedCacheObject> => undefined,
    getColaContext,
    registerForContext: jest.fn(),
    getIdentifier: (): ConfigurationIdentifier => ({identifier: 'a6limo', type: IdentifierType.Carline}),
    updateIdentifier: jest.fn(),
    getPreviewContext: jest.fn(),
    getSerializedStore: jest.fn(),
    clearStore: jest.fn(),
    removeQueryFromCache: jest.fn(),
    // eslint-disable-next-line react/display-name
    prepareRender: (App: () => JSX.Element): JSX.Element => <App />,
    resetConfiguration: jest.fn(),
  },
  'audi-cola-query-service': {
    QUERY_ITEMS_BY_GROUP: '',
    QUERY_CONFIGURATION_BASE_DATA: '',
    MUTATION_CHANGE_CONFIGURATION: '',
  },
  'locale-service': {
    language: 'de',
    countryCode: 'DE',
    currency: 'EUR',
  },
});

import React, {useCallback, useContext} from 'react';

import {AudiColaQueryServiceV1} from '@volkswagen-onehub/audi-cola-query-service';
import {LocaleServiceV1} from '@volkswagen-onehub/locale-service';
import {AsyncSsrManagerV1} from '@feature-hub/async-ssr-manager';
import {FeatureServices} from '@feature-hub/core';
import {ConfigurationIdentifier} from '@volkswagen-onehub/onegraph-client';

import {AudiColaServiceV1} from '../audi-cola-service-v1';
import {getColaContext} from './context';
import {InternalColaProvider} from './internal-provider';
import {name as packageName, version as packageVersion} from '../../../package.json';

export interface ColaProviderRequiredServices extends FeatureServices {
  readonly 'audi-cola-service': AudiColaServiceV1;
  readonly 'audi-cola-query-service': AudiColaQueryServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
}

interface CustomConfiguration {
  configurationContext: string;
  initialConfiguration: ConfigurationIdentifier;
}
export interface ColaProviderProps {
  customConfiguration?: CustomConfiguration;
  featureServices: ColaProviderRequiredServices;
  clientName?: string;
  clientVersion?: string;
}

export const CURRENT_CONFIGURATION_CONTEXT = 'CURRENT_CONFIGURATION_CONTEXT';

/**
 * Generates a client name based on a feature hub consumer id, i.e. the feature app id.
 * Removes possible hashes in the consumerId, prefixed by a colon ':'.
 *
 * e.g. "audi:stage-large-feature-app:8e95f74f" => "audi:stage-large-feature-app_via_@volkswagen-onehub/audi-cola-service_3.4.2-pre.4"
 */
export function generateClientName(consumerId:string): string {
  let clientNameConsumerId = consumerId || 'unknown';
  if (consumerId) {
    if (consumerId.indexOf(':') >= 0) {
      // In Nemo the feature app ids have a hash suffix, e.g. "audi:stage-large-feature-app:8e95f74f"
      // These hashes should not pollute the client name reporting and need to be splitted of.
      [, clientNameConsumerId] = consumerId.match(/(.*):(.*)/);
    } else if (consumerId.indexOf('|') >= 0) {
      // check for Appstore feature app id, e.g. A|0828a53c-cafd-4276-b760-d3a68f671327|10e41493
      const match = consumerId.match(/A\|([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})\|.+$/);
      if (match?.length >= 2) {
        clientNameConsumerId = match[1];
      }
    }
  }
  return `${clientNameConsumerId}_via_${packageName}_${packageVersion}`;
}

export function createColaProvider(consumerId: string): React.FC<ColaProviderProps> {
  const ColaProvider: React.FC<ColaProviderProps> = ({
    customConfiguration = {
      configurationContext: CURRENT_CONFIGURATION_CONTEXT,
      initialConfiguration: undefined,
    },
    featureServices,
    children,
    clientName,
    clientVersion,
  }) => {
    const context = useContext(getColaContext());
    const WrappingColaContext = useCallback(({children: colaChildren}) => {
      if (context) {
        return <>{colaChildren}</>;
      }
      return <InternalColaProvider
        featureServices={featureServices}
        customConfiguration={customConfiguration}
        clientName={clientName ? clientName : generateClientName(consumerId)}
        clientVersion={clientVersion ? clientVersion : 'unknown'}
      >
        {colaChildren}
      </InternalColaProvider>;
    }, [context, customConfiguration, featureServices, clientName, clientVersion]);
    return <WrappingColaContext>
      {children}
    </WrappingColaContext>;
  };

  return ColaProvider;
}

import React, {useContext, useEffect, useMemo} from 'react';

import {getApolloContext, PriceMode} from '@volkswagen-onehub/onegraph-client';

import {AudiColaServiceV1} from '../audi-cola-service-v1';
import {getColaContext} from './context';
import {ColaState} from './state';
import {ColaAction, createSetConfigurationIdentifierAction} from './actions';
import {colaReducer} from './reducers';
import {ColaProviderProps, CURRENT_CONFIGURATION_CONTEXT} from './provider';

export const InternalColaProvider: React.FC<ColaProviderProps> = ({
  customConfiguration = {configurationContext: CURRENT_CONFIGURATION_CONTEXT},
  featureServices,
  children,
  clientName,
  clientVersion,
}) => {
  const {configurationContext, initialConfiguration} = customConfiguration;

  const ColaContext = getColaContext();
  const colaService = useMemo((): AudiColaServiceV1 => featureServices['audi-cola-service'], [featureServices]);

  colaService.setInitialConfiguration(configurationContext, initialConfiguration);

  const [state, dispatch] = React.useReducer<React.Reducer<ColaState, ColaAction>>(colaReducer, {
    identifier: colaService.getIdentifier(configurationContext),
    configurationContext,
    priceMode: PriceMode.Price,
    colaService: featureServices['audi-cola-service'],
    localeService: featureServices['locale-service'],
    queryService: featureServices['audi-cola-query-service'],
    previewContext: featureServices['audi-cola-service'].getPreviewContext(),
    asyncSsrManager: featureServices['s2:async-ssr-manager'],
    clientName,
    clientVersion,
  });

  useEffect(() => {
    colaService.registerForContext(configurationContext, initialConfiguration, (identifier) => {
      dispatch(createSetConfigurationIdentifierAction(identifier));
    });
    // TODO: enable when financing calculation is implemented
    // const priceModeListener = (event: CustomEvent<string>): void => {
    //   dispatch(createSetPriceModeAction(event.detail === 'RATE' ? PriceMode.Rate : PriceMode.Price));
    // };
    //
    // if (typeof document !== 'undefined') {
    //   document.addEventListener('setPriceMode', priceModeListener);
    // }
    // return (): void => {
    //   if (typeof document !== 'undefined') {
    //     document.removeEventListener('setPriceMode', priceModeListener);
    //   }
    // };
  }, [configurationContext, colaService, initialConfiguration]);

  const ApolloContext = getApolloContext();
  const wrappingContext = useContext(ApolloContext);

  return (
    <ColaContext.Consumer>
      {(context): React.ReactElement => {
        const contextProps = context ? context : {state, dispatch};

        return (
          <ColaContext.Provider value={contextProps}>
            <ApolloContext.Provider value={{
              client: featureServices['audi-cola-service'].getApolloClient(),
              renderPromises: wrappingContext?.renderPromises,
            }}>
              {children}
            </ApolloContext.Provider>
          </ColaContext.Provider>
        );
      }}
    </ColaContext.Consumer>
  );
};

import {FeatureServiceBinder, SharedFeatureService, FeatureServices, Logger} from '@feature-hub/core';
import {
  defineFeatureService,
  FeatureServiceDefinition,
} from '@volkswagen-onehub/sonic-feature-hub-tools';
import {AudiColaQueryServiceV1} from '@volkswagen-onehub/audi-cola-query-service';
import {LocaleServiceV1} from '@volkswagen-onehub/locale-service';
import {AudiMarketContextServiceV1} from '@volkswagen-onehub/audi-market-context-service';
import {AsyncSsrManagerV1} from '@feature-hub/async-ssr-manager';
import {CurrentCarlineServiceV1} from '@volkswagen-onehub/audi-current-carline-service';
import {ChangeType, ChangeAction, IdentifierType, ConfigurationIdentifier, ItemGroup} from '@volkswagen-onehub/onegraph-client';

import {AudiColaServiceV1, AudiColaServiceV1Implementation} from './v1/audi-cola-service-v1';
import {useConfiguration, ConfigurationResult} from './v1/hooks/base/use-configuration';
import {useConfigurationChange} from './v1/hooks/base/use-configuration-change';
import {useConfigurationField} from './v1/hooks/custom/use-configuration-field';
import {useConfigurationInitWithItems} from './v1/hooks/custom/use-configuration-init-with-items';
import {useConfigurationItems} from './v1/hooks/base/use-configuration-items';
import {MockColaProvider} from './v1/context/mock-provider';

interface Service extends SharedFeatureService {
  readonly '1.0.0': FeatureServiceBinder<AudiColaServiceV1>;
}

export interface AudiColaServiceDependencies extends FeatureServices {
  readonly 'audi-cola-query-service': AudiColaQueryServiceV1;
  readonly 'audi-current-carline-service'?: CurrentCarlineServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-market-context-service'?: AudiMarketContextServiceV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:logger'?: Logger;

}

export interface AudiColaServicePreviewConfig {
  jwtToken: string;
}

export interface AudiColaServiceOptions {
  colaApiKey?: string;
  colaUrl?: string;
  colaDataVersionsUrl?: string;
  preview?: AudiColaServicePreviewConfig;
}

export const audiColaServiceId = 'audi-cola-service';

const definition: FeatureServiceDefinition<AudiColaServiceV1, AudiColaServiceOptions> = {
  id: audiColaServiceId,
  versions: {
    '1.0.0': AudiColaServiceV1Implementation,
  },
  dependencies: {
    featureServices: {
      'audi-cola-query-service': '^1.0.0',
      'locale-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-current-carline-service': '^1.0.0',
      'audi-market-context-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
    },
  },
};

const defineAudiColaService = defineFeatureService<
  AudiColaServiceV1,
  Service,
  AudiColaServiceOptions,
  AudiColaServiceDependencies>(definition);

export {
  AudiColaServiceV1,
  ChangeAction,
  ChangeType,
  ConfigurationIdentifier,
  ConfigurationResult,
  defineAudiColaService,
  IdentifierType,
  ItemGroup,
  MockColaProvider,
  useConfiguration,
  useConfigurationChange,
  useConfigurationField,
  useConfigurationInitWithItems,
  useConfigurationItems,
};

export {gql} from 'graphql-tag';

import {useCallback, useContext} from 'react';

import {
  FetchResult,
  gql,
  IdentifierType,
  Mutation,
  MutationConfigurationChangeArgs,
  MutationFunctionOptions,
  MutationTuple,
  useMutation,
} from '@volkswagen-onehub/onegraph-client';

import {configurationChangeNemo} from '../../nemo-sync/configuration-change-nemo';
import {getColaContext} from '../../context/context';
import {useRequiredVariables} from '../internal/use-require-variables';

export type ChangeArgs = Pick<MutationConfigurationChangeArgs, 'change'>;

export const useConfigurationChange = (): MutationTuple<Mutation, ChangeArgs> => {
  const {state} = useContext(getColaContext());

  const [originalCallback, originalArgs] = useMutation<Mutation, MutationConfigurationChangeArgs>(
    gql`${state.queryService.MUTATION_CHANGE_CONFIGURATION}`,
    {
      update: (cache, mutationResult) => {
        state.colaService.updateIdentifier(state.configurationContext, {
          type: IdentifierType.Prstring,
          identifier: mutationResult.data.configurationChange.prString,
        });
      },
      context: {
        consumerId: `${state.clientName}@${state.clientVersion}`,
      },
    }
  );

  const requiredVariables = useRequiredVariables();

  const mutationCallback = useCallback(
    (options: MutationFunctionOptions<Mutation, ChangeArgs>): Promise<FetchResult<Mutation>> => {
      const mutationArgs: MutationFunctionOptions<Mutation, MutationConfigurationChangeArgs> = {
        ...originalArgs,
        variables: {
          ...requiredVariables,
          change: options.variables.change,
        },
      };

      configurationChangeNemo(mutationArgs.variables.change.itemId);
      return originalCallback(mutationArgs);
    },
    [originalArgs, originalCallback, requiredVariables]);

  return [mutationCallback, originalArgs];
};
